import React, { useState, useEffect, useReducer } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
  IconButton,
  Autocomplete,
  TextField,
  DialogActions,
  Button
} from "@mui/material";
import { ACCEPTENCE_STATUS, COURSE_MATERIAL_TYPE } from "./consts";
import {
  extractDataForTasksShare,
  extractIdsFromArray,
  renderExportItems
} from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../../../hooks";
import { httpCallables } from "../../../../firebase";
import { v4 as uuid } from "uuid";
import { add } from "date-fns";
import { addSnackbar } from "../../../../redux/snackbarSlice";
import CloseIcon from "@mui/icons-material/Close";
import { TooltipWithIntl } from "../../../SharedComponents/index.js";

const initialState = {
  texts: [],
  tasks: [],
  courses: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SELECTED_TEXTS":
    case "SET_SELECTED_TASKS":
    case "SET_SELECTED_COURSES":
      return {
        ...state,
        [action.type.split("_")[2].toLowerCase()]: action.payload
      };
    default:
      return state;
  }
};

const ExportModal = ({
  exportModal,
  setExportModal,
  tasks: initialTasks = [],
  courseTexts,
  type,
  courseAndTeachers
}) => {
  const { course_id } = useQuery();
  const [hovered, setHovered] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const storeDispatch = useDispatch();
  const intl = useIntl();

  const modalHeader =
    type === COURSE_MATERIAL_TYPE.TASK ? "Share assignments" : "Share texts";
  const isShareDisabled =
    state.courses.length === 0 ||
    (type === COURSE_MATERIAL_TYPE.TASK
      ? state.tasks.length
      : state.texts.length) === 0;

  const userName = useSelector((state) => state.user.auth.displayName);
  const handleClose = () => {
    setExportModal(false);
    resetState();
  };

  const resetState = () => {
    dispatch({ type: "SET_SELECTED_TEXTS", payload: courseTexts });
    dispatch({ type: "SET_SELECTED_COURSES", payload: [] });
    dispatch({
      type: "SET_SELECTED_TASKS",
      payload: initialTasks.map((task) => ({ ...task, includeText: true }))
    });
  };

  const onIncludeTextToggle = (taskId) => {
    const updatedTasks = state.tasks.map((task) => {
      if (task.id === taskId) {
        return { ...task, includeText: !task.includeText };
      } else return task;
    });
    dispatch({ type: "SET_SELECTED_TASKS", payload: updatedTasks });
  };

  const handleChange = (event, item) => {
    const { name, checked } = event.target;
    const payload = checked
      ? [...state[name], { ...item, includeText: true }]
      : state[name].filter((selectedItemId) => selectedItemId.id !== item.id);
    dispatch({ type: `SET_SELECTED_${name.toUpperCase()}`, payload });
  };

  const handleSelectAll = (event) => {
    const { name, checked } = event.target;
    let payload;
    switch (name.toUpperCase()) {
      case "TASKS":
        payload = checked
          ? initialTasks.map((task) => ({ ...task, includeText: true }))
          : [];
        break;
      case "TEXTS":
        payload = checked ? courseTexts : [];
        break;
      case "COURSES":
        payload = checked ? courseAndTeachers : [];
        break;
    }
    dispatch({ type: `SET_SELECTED_${name.toUpperCase()}`, payload });
  };

  const handleShare = () => {
    const coursesPayload = extractIdsFromArray(state.courses);

    const status = Object.fromEntries(
      coursesPayload.map((id) => [id, ACCEPTENCE_STATUS.PENDING])
    );

    const dataObj = {
      sender: userName,
      created_at: new Date(),
      senderCourseId: course_id,
      status: status,
      ttl: add(new Date(), { months: 6 }),

      id: uuid()
    };
    if (type === COURSE_MATERIAL_TYPE.TASK) {
      const tasksPayload = extractDataForTasksShare(state.tasks);
      const data = {
        ...dataObj,
        type: COURSE_MATERIAL_TYPE.TASK,
        tasks: tasksPayload,
        courses: coursesPayload
      };
      httpCallables
        .coursesFunctions({
          func_name: "saveCourseMaterialToFirestore",
          data
        })
        .then((response) => {
          const { success } = response.data;
          if (success) {
            storeDispatch(
              addSnackbar({
                message: intl.formatMessage({
                  id: "shareCourseMaterial.undo",
                  defaultMessage: "Tasks shared successfullly"
                }),
                actions: [
                  {
                    intlId: "undo",
                    infDefaultMsg: "undo",
                    callBack: "undoShareCourseMaterial"
                  }
                ],
                data: {
                  itemId: data.id
                }
              })
            );
          }
        });
    } else if (type === COURSE_MATERIAL_TYPE.TEXT) {
      const textsPayload = extractIdsFromArray(state.texts);
      const data = {
        ...dataObj,
        type: COURSE_MATERIAL_TYPE.TEXT,
        texts: textsPayload,
        courses: coursesPayload
      };
      httpCallables
        .coursesFunctions({
          func_name: "saveCourseMaterialToFirestore",
          data
        })
        .then((response) => {
          const { success } = response.data;
          if (success) {
            storeDispatch(
              addSnackbar({
                message: intl.formatMessage({
                  id: "shareCourseMaterial.undo",
                  defaultMessage: "Texts shared successfully"
                }),
                actions: [
                  {
                    intlId: "undo",
                    infDefaultMsg: "undo",
                    callBack: "undoShareCourseMaterial"
                  }
                ],
                data: {
                  itemId: data.id
                }
              })
            );
          }
        });
    }
    handleClose();
  };

  useEffect(() => {
    resetState();
  }, [course_id]);

  return (
    <Dialog
      open={exportModal}
      PaperProps={{
        style: {
          width: "700px",
          height: "600px",
          borderRadius: "12px",
          padding: "13px 24px"
        }
      }}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          padding: "0px 0px 13px 0px"
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexFlow: "column",
            width: "100%"
          }}>
          <Typography variant="h6">{modalHeader}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center"
          }}>
          <TooltipWithIntl
            defaultMessage="Close"
            intlStringId="general.close"
            placement="top">
            <IconButton
              onClick={handleClose}
              size="small"
              color="inherit"
              aria-label="Close">
              <CloseIcon />
            </IconButton>
          </TooltipWithIntl>
        </Box>
      </DialogTitle>
      <Divider variant="fullWidth" sx={{ marginInline: "-24px" }} />
      <DialogContent
        sx={{
          overflow: "hidden",
          paddingInline: "0px",
          display: "flex",
          flexDirection: "column",
          gap: "28px"
        }}>
        <Autocomplete
          id="selectCourse"
          options={courseAndTeachers}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              name="select-course"
              aria-label="select-course-input"
              label={"Destination Course"}
            />
          )}
          onChange={(event, newValue) => {
            dispatch({
              type: `SET_SELECTED_COURSES`,
              payload: newValue ? [newValue] : []
            });
          }}
          aria-label="select-course"
          aria-required="true"
          aria-describedby="select-course-input"
        />
        <Box
          sx={{
            border: 1,
            borderColor: (theme) => theme.palette.action.disabled,
            borderRadius: 1,
            overflow: "auto",
            maxHeight: 300
          }}>
          {renderExportItems(
            type,
            initialTasks,
            courseTexts,
            handleChange,
            setHovered,
            hovered,
            onIncludeTextToggle,
            state.tasks,
            state.texts,
            handleSelectAll
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleShare}
          disabled={isShareDisabled}>
          Share
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportModal;
