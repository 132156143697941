import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useQuery from "./useQuery";
import { setSelectedCourseId } from "../redux/coursesSlice";

function useCourseId() {
  const dispatch = useDispatch();
  const { course_id } = useQuery();
  const selectedCourseId = useSelector(
    (state) => state.user.userProfile?.selectedCourseId
  );

  useEffect(() => {
    if (course_id) {
      dispatch(setSelectedCourseId(Number(course_id)));
    }
  }, [course_id, dispatch]);

  return Number(course_id) || selectedCourseId || undefined;
}

export default useCourseId;
