import React, { useState, useRef, useEffect } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { TableRow, TableCell } from "@mui/material";

export default function SortableText(props) {
  const [isDragging, setIsDragging] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: `sort-libary-texts-${props.id}`,
      data: {
        textId: props.id,
        type: "SortLibraryTexts",
        onSortTexts: props.onSortTexts
      }
    });

  const itemRef = useRef(null);

  // Handle focus visibility
  useEffect(() => {
    const handleFocus = () => {
      if (itemRef.current) {
        itemRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest"
        });
      }
    };

    const element = itemRef.current;
    if (element) {
      element.addEventListener("focus", handleFocus, true);
      return () => element.removeEventListener("focus", handleFocus, true);
    }
  }, []);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    cursor: "pointer",
    backgroundColor: isFocused ? "rgba(0, 123, 255, 0.1)" : "inherit"
  };

  const handleMouseDown = (e) => {
    // Check if the click is on the action icons
    if (e.target.closest(".action-icons")) {
      return;
    }

    const startTime = new Date().getTime();

    const handleMouseUp = () => {
      const endTime = new Date().getTime();
      const duration = endTime - startTime;

      if (duration < 200) {
        // Adjust this threshold as needed
        props.onClick && props.onClick(e);
      } else {
        setIsDragging(true);
      }

      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      props.onClick && props.onClick(e);
    } else if (
      props.course?.course_role === "Teacher" &&
      (e.key === "ArrowUp" || e.key === "ArrowDown")
    ) {
      e.preventDefault();
      const direction = e.key === "ArrowUp" ? -1 : 1;
      props.onSortTexts(props.id, props.id, direction);
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const sortableProps =
    props.course?.course_role === "Teacher"
      ? {
          ref: (node) => {
            setNodeRef(node);
            if (node) itemRef.current = node;
          },
          style: style,
          ...attributes,
          ...listeners,
          onMouseDown: handleMouseDown,
          onDragEnd: handleDragEnd,
          tabIndex: 0,
          onKeyDown: handleKeyDown,
          onFocus: () => setIsFocused(true),
          onBlur: () => setIsFocused(false)
        }
      : {
          ref: itemRef,
          onClick: props.onClick,
          style: { ...style, cursor: "pointer" },
          tabIndex: 0,
          onKeyDown: handleKeyDown,
          onFocus: () => setIsFocused(true),
          onBlur: () => setIsFocused(false)
        };

  const { onSortTexts, onClick, children, ...nonDnDProps } = props;

  const [isHovered, setIsHovered] = useState(false);

  const mouseEnter = () => {
    setIsHovered(true);
  };

  const mouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <TableRow
      {...sortableProps}
      {...nonDnDProps}
      role="row"
      data-testid="text-row"
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          hovered: isHovered
        });
      })}
    </TableRow>
  );
}
