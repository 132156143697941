import React from "react";
import {
  FormControl,
  Checkbox,
  FormControlLabel,
  Typography,
  TextField,
  Box,
  Button,
  IconButton
} from "@mui/material";
import ScrollBox from "../../../SharedComponents/ScrollBox";
import SearchIcon from "@mui/icons-material/Search";
import { useIntl } from "react-intl";
import { COURSE_MATERIAL_TYPE } from "./consts.js";

const truncateEnd = (text, maxLength, variant) => {
  if (!text) return "";
  if (text.length <= maxLength) {
    return <Typography variant={variant}>{text}</Typography>;
  }
  const ellipsis = "...";
  const snippetLength = Math.floor((maxLength - ellipsis.length) / 2);
  const snippetStart = text.slice(0, snippetLength);
  const snippetEnd = text.slice(-snippetLength);
  return (
    <Typography variant={variant} sx={{ width: "270px" }}>
      {snippetStart}
      {snippetEnd}
      {ellipsis}
    </Typography>
  );
};

export const renderTextItem = (
  texts,
  selectedTexts,
  handleChange,
  handleSelectAll
) => {
  return (
    <ScrollBox>
      <FormControl
        sx={{
          width: "100%"
        }}>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row",
            alignContent: "center",
            alignItems: "center",
            borderBottom: "1px solid #7D7D7D",
            paddingBlock: "0px",
            paddingInlineStart: "8px",
            width: "100%"
          }}>
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={
                  selectedTexts.length > 0 &&
                  selectedTexts.length < texts.length
                }
              />
            }
            label={"Select All"}
            name="texts"
            onChange={(e) => handleSelectAll(e)}
            checked={selectedTexts.length === texts.length}
          />
        </Box>
        {texts.map((item) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                alignContent: "center",
                alignItems: "center",
                borderBottom: "1px solid #e0e0e0",
                paddingBlock: "4px",
                paddingInlineStart: "8px",
                width: "100%"
              }}
              key={item.id}>
              <FormControlLabel
                key={item.id}
                checked={selectedTexts.some((text) => text.id === item.id)}
                control={<Checkbox value={item.name} />}
                label={item.name}
                name="texts"
                onChange={(e) => handleChange(e, item)}
              />
            </Box>
          );
        })}
      </FormControl>
    </ScrollBox>
  );
};

export const renderExportItems = (
  type,
  tasks,
  texts,
  handleChange,
  setHovered,
  hovered,
  onIncludeTextToggle,
  selectedTasks,
  selectedTexts,
  handleSelectAll
) => {
  const intl = useIntl();
  const selectAllChecked =
    type === COURSE_MATERIAL_TYPE.TASK
      ? selectedTasks.length === tasks.length
      : selectedTexts.length === texts.length;
  const selectAllIndeterminate =
    type === COURSE_MATERIAL_TYPE.TASK
      ? selectedTasks.length > 0 && selectedTasks.length < tasks.length
      : selectedTexts.length > 0 && selectedTexts.length < texts.length;
  const itemsToShare = type === COURSE_MATERIAL_TYPE.TASK ? tasks : texts;
  const selectedItems =
    type === COURSE_MATERIAL_TYPE.TASK ? selectedTasks : selectedTexts;
  return (
    <ScrollBox>
      <FormControl
        sx={{
          width: "100%"
        }}>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row",
            borderBottom: "1px solid #e0e0e0",
            paddingBlock: "4px",
            paddingInline: "18px",
            minHeight: "52px",
            width: "100%",
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 1
          }}>
          <FormControlLabel
            label={"Select All"}
            checked={selectAllChecked}
            control={
              <Checkbox
                sx={{ margin: 0, padding: 0, marginInlineEnd: "15px" }}
                indeterminate={selectAllIndeterminate}
                onChange={(e) => handleSelectAll(e)}></Checkbox>
            }
            name={type}
          />
        </Box>
        {itemsToShare.map((item) => {
          const currentItem = selectedItems.filter(
            (itemToFilter) => itemToFilter.id === item.id
          )[0];
          const isSelected = selectedItems.some(
            (selectedItem) => selectedItem.id === item.id
          );

          return (
            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                borderBottom: "1px solid #e0e0e0",
                paddingBlock: "4px",
                paddingInline: "18px",
                width: "100%",
                minHeight: "52px",
                backgroundColor: isSelected ? "#1565C00A" : null,
                "&:hover": {
                  backgroundColor: "#1565C014"
                }
              }}
              key={item.id}
              onMouseEnter={() => setHovered(item.id)}
              onMouseLeave={() => setHovered(null)}>
              <FormControlLabel
                checked={isSelected}
                key={item.id}
                aria-label={"Select item"}
                control={
                  <Checkbox
                    value={item.name}
                    defaultChecked
                    onChange={(e) => handleChange(e, item)}
                    sx={{ margin: 0, padding: 0 }}
                  />
                }
                name={type}
              />
              <Box sx={{ alignContent: "center" }}>
                {truncateEnd(item.name, 30, "body1")}
                {type === COURSE_MATERIAL_TYPE.TASK &&
                  item.status === "Draft" && (
                    <Typography variant="caption">- Draft</Typography>
                  )}
              </Box>
              {currentItem && type === COURSE_MATERIAL_TYPE.TASK && (
                <Button
                  variant="outlined"
                  sx={{
                    marginRight: "4px",
                    marginLeft: "auto",
                    marginTop: "4px",
                    opacity:
                      hovered === item.id || !currentItem.includeText ? 1 : 0,
                    transition: "opacity 0.2s",
                    "&:focus": {
                      opacity: 1
                    }
                  }}
                  onClick={(e) => {
                    if (currentItem) {
                      onIncludeTextToggle(currentItem.id);
                    }
                    !currentItem.includeText && e.target.blur();
                  }}>
                  {currentItem.includeText
                    ? intl.formatMessage({
                        id: "task.removeReading",
                        defaultMessage: "Remove reading"
                      })
                    : intl.formatMessage({
                        id: "task.reattachReading",
                        defaultMessage: "Reattach reading"
                      })}
                </Button>
              )}
            </Box>
          );
        })}
      </FormControl>
    </ScrollBox>
  );
};

export const renderCourseAndTeacher = (
  coursesAndTeachers,
  searchQuery,
  filteredResults,
  handleSearch,
  handleChange,
  handleSelectAll,
  selectedCourses
) => {
  return (
    <ScrollBox>
      <FormControl
        sx={{
          width: "100%"
        }}>
        <TextField
          id="outlined-basic"
          label="Search course"
          variant="outlined"
          value={searchQuery}
          sx={{ margin: "10px" }}
          onChange={handleSearch}>
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </TextField>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            alignContent: "center",
            borderBottom: "1px solid #7D7D7D",
            paddingInlineStart: "8px",
            width: "100%"
          }}>
          <FormControlLabel
            label={"Select All"}
            control={
              <Checkbox
                sx={{ marginInlineEnd: "15px" }}
                indeterminate={
                  selectedCourses.length > 0 &&
                  selectedCourses.length < coursesAndTeachers.length
                }
                onChange={(e) => handleSelectAll(e)}>
                checked={selectedCourses.length === coursesAndTeachers.length}
              </Checkbox>
            }
            name="courses"
          />
        </Box>

        {filteredResults.map((item) => (
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              alignContent: "center",
              alignItems: "center",
              borderBottom: "1px solid #e0e0e0",
              paddingBlock: "4px",
              paddingInlineStart: "8px",
              width: "100%"
            }}
            key={item.id}>
            <FormControlLabel
              checked={selectedCourses.some((course) => course.id === item.id)}
              key={item.id}
              onChange={(e) => handleChange(e, item)}
              control={<Checkbox value={item.name} />}
              name="courses"
              aria-label={"Select course"}
            />
            <Box sx={{ display: "flex", flexFlow: "column" }}>
              <Box sx={{ padding: 0 }}>
                <Typography variant="body1">{item.name}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  alignContent: "flex-start"
                }}>
                <Box
                  sx={{
                    display: "flex",
                    flexFlow: "row"
                  }}>
                  {item.teachers.slice(0, 2).map((teacher, index) => (
                    <React.Fragment key={teacher}>
                      <Typography variant="body2">{teacher}</Typography>

                      {index === 1 && item.teachers.length > 2 && (
                        <span>...</span>
                      )}
                      {index !== Math.min(1, item.teachers.length - 1) &&
                        index !== 1 && <span>,&nbsp; </span>}
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </FormControl>
    </ScrollBox>
  );
};

export const extractDataForTasksShare = (array) => {
  return array.map((item) => {
    const { id, text_id, includeText, status } = item;
    return { id, text_id, includeText, status };
  });
};

export const extractIdsFromArray = (array) => {
  return array.map((item) => item.id);
};
