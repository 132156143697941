import React from "react";

import { httpCallables } from "../../firebase";

import { Box, TableCell, IconButton } from "@mui/material";
import { useIntl } from "react-intl";

import { TooltipWithIntl } from "../SharedComponents";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { captureException } from "../../utils/errorHandlers";

import { useSelector, useDispatch } from "react-redux";
import { addSnackbar, clearSnackbars } from "../../redux/snackbarSlice";
import { selectTasks } from "../../redux/tasksSlice";
import { selectTexts } from "../../redux/textsSlice";
import { setTexts } from "../../redux/textsSlice";

const useStyles = makeStyles((theme) => ({
  cell: {
    marginTop: "16px",
    marginBottom: "16px",
    height: "auto"
  },
  link: {
    color: theme.palette.text.primary
  },
  authorName: {
    whiteSpace: "nowrap",
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  textName: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    textOverflow: "ellipsis",
    "-webkit-box-orient": "vertical",
    overflow: "hidden"
  },
  categoriesAndActions: {
    display: "flex",
    justifyContent: "space-between",
    height: "73px"
  }
}));
export const TextColumns = ({
  text,
  hovered,
  isTeacher,
  setOpenTexts,
  setSelectedText,
  className
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const dispatch = useDispatch();

  const selectedCourseId = useSelector(
    (state) => state.user.userProfile?.selectedCourseId
  );
  const tasks = useSelector((state) =>
    selectTasks(state, selectedCourseId?.toString())
  );

  const texts = useSelector((state) => selectTexts(state, selectedCourseId));
  const textHasTasks = Boolean(
    tasks.filter((task) => {
      return task.text_id === text.id;
    }).length
  );

  async function deleteTextFromLibrary(text) {
    try {
      dispatch(
        addSnackbar({
          message: intl.formatMessage({
            id: "comments.onDelete",
            defaultMessage: "Deleting..."
          })
        })
      );
      const textToDelete = text;
      const filteredTexts = texts.filter((text) => {
        if (text.id === textToDelete.id) return;
        return text;
      });
      const data = {
        text_id: text.id,
        course_id: selectedCourseId,
        file_url: text.file_url
      };
      dispatch(setTexts(filteredTexts));
      await httpCallables.deleteCourseText(data).then(() => {
        dispatch(clearSnackbars());
        dispatch(
          addSnackbar({
            message: intl.formatMessage({
              id: "comments.onDelete",
              defaultMessage: "Text deleted"
            }),
            actions: [
              {
                intlId: "undo",
                intlDefaultMsg: "undo",
                callBack: "undoDeleteText"
              }
            ],
            data: {
              textToDelete
            }
          })
        );
      });
    } catch (error) {
      const ERROR_MSG = {
        message: intl.formatMessage({
          id: "error.deletingFailed",
          defaultMessage: `Failed to delete text ${text.name} with id:${text.id}`
        }),
        severity: "error"
      };
      captureException(
        error,
        `Failed to delete text ${text.name} with id:${text.id}`
      );
      dispatch(addSnackbar(ERROR_MSG));
      return false;
    }
  }

  return (
    <>
      <TableCell className={clsx(classes.cell, className)} align="left">
        <span className={clsx(classes.link, classes.authorName)}>
          {text.author}
        </span>
      </TableCell>
      <TableCell className={clsx(classes.cell, className)} align="left">
        <span className={clsx(classes.link, classes.textName)}>
          {text.name}
        </span>
      </TableCell>
      <TableCell
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={clsx(classes.categoriesAndActions, className)}
        align="right">
        {/* TODO - once categories mean something, uncomment this code */}
        {/* <Box
          sx={{
            display: "row wrap"
          }}
        >
          {text.categories && text.categories.length
            ? text.categories
                .split(",")
                .map((item, index) => {
                  return (
                    <Chip
                      className={classes.chip}
                      key={index}
                      label={item}
                    />
                  );
                })
            : " "}
        </Box> */}

        {isTeacher && (
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              width: "100px",
              opacity: hovered ? 1 : 0,
              "&:focus, &:focus-within": {
                opacity: 1
              }
            }}>
            <TooltipWithIntl
              intlStringId={"text.edit.msg"}
              defaultMessage={"Edit text"}
              placement={"top"}>
              <IconButton
                aria-label={"edit text"}
                disabled={false}
                size={"medium"}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                onClick={(e) => {
                  setOpenTexts("Edit");
                  setSelectedText(text);
                  e.stopPropagation();
                }}>
                <EditOutlinedIcon />
              </IconButton>
            </TooltipWithIntl>
            {textHasTasks ? (
              <TooltipWithIntl
                intlStringId={"text.delete.msg"}
                defaultMessage={
                  "Remove associated tasks \n before deleting the text"
                }
                placement={"bottom"}>
                <IconButton
                  disabled={false}
                  size={"medium"}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  aria-label={"delete text"}
                  className={classes.disabledDeleteBtn}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </TooltipWithIntl>
            ) : (
              <TooltipWithIntl
                intlStringId={"text.delete.msg"}
                defaultMessage={"Delete text"}
                placement={"top"}>
                <IconButton
                  disabled={textHasTasks}
                  size={"medium"}
                  aria-label={"delete text"}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  onClick={async (e) => {
                    !textHasTasks && (await deleteTextFromLibrary(text));
                    e.stopPropagation();
                  }}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </TooltipWithIntl>
            )}
          </Box>
        )}
      </TableCell>
    </>
  );
};
